import React from 'react'

class TheButton extends React.Component {
  render() {
    return (
      <a
        className="button"
        href={this.props.link}
        target="_blank"
        rel="noreferrer"
      >
        {this.props.text}
      </a>
    )
  }
}

export default TheButton
