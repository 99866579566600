export default function is_upcoming(date) {
  let today = parseInt(
    new Date().toISOString().substring(0, 10).replace(/-/g, '')
  )
  let nodeDate = new Date(date)

  try {
    nodeDate =
      parseInt(nodeDate.toISOString().substring(0, 10).replace(/-/g, '')) + 1
  } catch (error) {
    // probably invalid date; try/catch prevents crashing
    nodeDate = 0
  }

  //console.log('nodeDate', nodeDate)

  return nodeDate >= today
}
