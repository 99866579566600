import React from 'react'
import LazyLoad from 'react-lazyload'
import Img from 'gatsby-image'

class NavelImage extends React.Component {
  render() {
    let image = this.props.image
    let output
    let offset = 1400

    if (image && image.childImageSharp && image.childImageSharp.fluid) {
      output = (
        <Img
          srcSet={image.childImageSharp.fluid.srcSet}
          fluid={image.childImageSharp.fluid}
          src={image.childImageSharp.fluid.src}
          style={this.props.imageStyle}
          className={this.props.imageClass}
          imgStyle={{ objectFit: 'contain' }}
          alt=""
        />
      )
    } else if (image && image.childImageSharp && image.childImageSharp.fixed) {
      output = (
        <Img
          srcSet={image.childImageSharp.fixed.srcSet}
          fixed={image.childImageSharp.fixed}
          src={image.childImageSharp.fixed.src}
          style={this.props.imageStyle}
          className={this.props.imageClass}
          imgStyle={{ objectFit: 'contain' }}
          alt=""
        />
      )
    } else {
      output = (
        <img
          src={image ? image.publicURL : ''}
          style={this.props.imageStyle}
          className={this.props.imageClass}
          alt=""
        />
      )
    }

    return <LazyLoad offset={offset}>{output}</LazyLoad>
  }
}

export default NavelImage
