import React from 'react'
import { Link } from 'gatsby'

import logo from '../../assets/images/logo.svg'

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <div className="header-inner">
          <h1>
            <Link to="/">
              <img src={logo} alt="" />
            </Link>
          </h1>
        </div>
      </div>
    )
  }
}

export default Header
