import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { connect } from 'react-redux'

import {
  onNewsletter,
  offNewsletter,
  toggleNewsletter,
} from '../../redux/actions'

class AlertNewsletter extends React.Component {
  state = {
    email: null,
    text: 'Stay connected with us',
  }

  _showPopup = () => {
    sessionStorage.setItem('popup1', false)
    this.props.onNewsletter()
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    let posY = window.scrollY
    let headerHeight = window.innerHeight

    return posY >= headerHeight && !sessionStorage.getItem('popup1')
      ? this._showPopup()
      : null
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  _successfulAdd() {
    setTimeout(() => this.props.offNewsletter(), 3000)
  }

  _closeOverlay = () => {
    this.props.offNewsletter()
    window.removeEventListener('scroll', this.handleScroll)
  }

  _handleChange = (e) => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  _handleSubmit = (e) => {
    e.preventDefault()

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        if (result !== 'success') {
          throw msg
        }
        this.setState({ text: msg })
        this._successfulAdd()
      })
      .catch((err) => {
        this.setState({
          text: <div dangerouslySetInnerHTML={{ __html: err }} />,
        })
      })
  }

  render() {
    return (
      <div className={this.props.newsletter === true ? 'alert' : 'no-alert'}>
        <div className="alert-inner">
          <button
            type="button"
            className="alert-close"
            onClick={this._closeOverlay}
          >
            <span>&times;</span>
          </button>
          <div className={'alert-description'}>{this.state.text}</div>
          <div className={'alert-input'}>
            <form onSubmit={this._handleSubmit}>
              <input
                type="email"
                onChange={this._handleChange}
                placeholder="Email Address"
                name="email"
              />
              <button type="submit">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  newsletter: state.toggle,
})

const mapDispatchToProps = {
  toggleNewsletter,
  onNewsletter,
  offNewsletter,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertNewsletter)
