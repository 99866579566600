import React from 'react'
import ListingItem from '../ListingItem'
import is_upcoming from '../../utils/upcoming'

class AssembliesListing extends React.Component {
  render() {
    let data = this.props.data
    let slug = this.props.pageContext.slug
    let assemblySpring2020 = []
    let assemblyFall2020 = []
    let assemblyEvents = []
    let assemblyCalls = []
    let assemblySignups = []

    if (data !== null) {
      assemblySpring2020.upcoming = data.edges
        .filter(
          (node) =>
            is_upcoming(node.node.frontmatter.date) &&
            node.node.frontmatter.eventType !== null &&
            node.node.frontmatter.eventType.assemblies === 'page' &&
            node.node.frontmatter.eventType.childOf === 'assemblies-spring2020'
        )
        .reverse()
      assemblySpring2020.past = data.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) &&
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.assemblies === 'page' &&
          node.node.frontmatter.eventType.childOf === 'assemblies-spring2020'
      )
    }

    if (data !== null) {
      assemblyFall2020.upcoming = data.edges
        .filter(
          (node) =>
            is_upcoming(node.node.frontmatter.date) &&
            node.node.frontmatter.eventType !== null &&
            node.node.frontmatter.eventType.assemblies === 'page' &&
            node.node.frontmatter.eventType.childOf === 'assemblies-fall2020'
        )
        .reverse()
      assemblyFall2020.past = data.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) &&
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.assemblies === 'page' &&
          node.node.frontmatter.eventType.childOf === 'assemblies-fall2020'
      )
    }

    if (data !== null) {
      assemblyEvents.upcoming = data.edges
        .filter(
          (node) =>
            is_upcoming(node.node.frontmatter.date) &&
            node.node.frontmatter.eventType !== null &&
            node.node.frontmatter.eventType.assemblies === 'event'
        )
        .reverse()
      assemblyEvents.past = data.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) &&
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.assemblies === 'event'
      )
    }

    if (data !== null) {
      assemblyCalls.upcoming = data.edges
        .filter(
          (node) =>
            is_upcoming(node.node.frontmatter.date) &&
            node.node.frontmatter.eventType !== null &&
            node.node.frontmatter.eventType.assemblies === 'open call'
        )
        .reverse()
      assemblyCalls.past = data.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) &&
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.assemblies === 'open call'
      )
    }

    if (data !== null) {
      assemblySignups.upcoming = data.edges
        .filter(
          (node) =>
            is_upcoming(node.node.frontmatter.date) &&
            node.node.frontmatter.eventType !== null &&
            node.node.frontmatter.eventType.assemblies === 'signup'
        )
        .reverse()
      assemblySignups.past = data.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) &&
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.assemblies === 'signup'
      )
    }

    return (
      <div
        className={
          'tags-listing' + (assemblySpring2020.length ? '' : ' no-assemblies')
        }
      >
        {slug === '/assemblies/assemblies/' && (
          <div className="tags-section-wrap upcoming">
            {assemblyFall2020.upcoming.length > 0 && (
              <div className="tags-section upcoming">Fall 2020</div>
            )}
            <div className="tags-listing-wrap">
              {assemblyFall2020.upcoming.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/assemblies/' && (
          <div className="tags-section-wrap upcoming">
            {assemblyFall2020.past.length > 0 && (
              <div className="tags-section upcoming">Fall 2020</div>
            )}
            <div className="tags-listing-wrap">
              {assemblyFall2020.past.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/assemblies/' && (
          <div className="tags-section-wrap upcoming">
            {assemblySpring2020.upcoming.length > 0 && (
              <div className="tags-section upcoming">Spring 2020</div>
            )}
            <div className="tags-listing-wrap">
              {assemblySpring2020.upcoming.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/assemblies/' && (
          <div className="tags-section-wrap upcoming">
            {assemblySpring2020.past.length > 0 && (
              <div className="tags-section upcoming">Spring 2020</div>
            )}
            <div className="tags-listing-wrap">
              {assemblySpring2020.past.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/events/' && (
          <div className="tags-section-wrap upcoming">
            {assemblyEvents.upcoming.length > 0 && (
              <div className="tags-section upcoming">Upcoming</div>
            )}
            <div className="tags-listing-wrap">
              {assemblyEvents.upcoming.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/events/' && (
          <div className="tags-section-wrap past">
            {assemblyEvents.past.length > 0 && (
              <div className="tags-section upcoming">Past</div>
            )}
            <div className="tags-listing-wrap">
              {assemblyEvents.past.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/propose/' && (
          <div className="tags-section-wrap upcoming">
            {assemblyCalls.upcoming.length > 0 && (
              <div className="tags-section upcoming">Open Call</div>
            )}
            <div className="tags-listing-wrap">
              {assemblyCalls.upcoming.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/propose/' && (
          <div className="tags-section-wrap upcoming">
            {assemblyCalls.past.length > 0 && (
              <div className="tags-section upcoming">Past</div>
            )}
            <div className="tags-listing-wrap">
              {assemblyCalls.past.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/participate/' && (
          <div className="tags-section-wrap upcoming">
            {assemblySignups.upcoming.length > 0 && (
              <div className="tags-section upcoming">Current</div>
            )}
            <div className="tags-listing-wrap">
              {assemblySignups.upcoming.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}

        {slug === '/assemblies/participate/' && (
          <div className="tags-section-wrap upcoming">
            {assemblySignups.past.length > 0 && (
              <div className="tags-section upcoming">Past</div>
            )}
            <div className="tags-listing-wrap">
              {assemblySignups.past.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default AssembliesListing
