import React from 'react'
import { Link } from 'gatsby'

import primaryColor from '../../utils/primaryColor'

class Nav extends React.Component {
  render() {
    let edges = this.props.items

    if (edges) {
      edges = edges.edges
    } else {
      new Array(edges)
    }

    return (
      <div className={'nav + nav-' + this.props.position} style={{}}>
        <ul className="nav-list">
          <li>
            <Link to="/programs/" activeStyle={{ color: primaryColor() }}>
              Programs
            </Link>
          </li>
          <li style={{ order: 3 }} className="nav-news">
            <Link to="/news/" activeStyle={{ color: primaryColor() }}>
              News
            </Link>
          </li>
          <li style={{ order: 4 }} className="nav-support">
            <a
              href="https://withfriends.co/navel/join/"
              target="_blank"
              rel="noreferrer"
            >
              Support
            </a>
          </li>
          <li style={{ order: 7 }} className="nav-store">
            <a
              href="https://navel-la.square.site/"
              target="_blank"
              rel="noreferrer"
            >
              Store
            </a>
          </li>

          {edges.map(({ node }) => (
            <li
              key={node.id}
              className={
                node.frontmatter.hideFromSubnav ? 'hide-from-subnav' : ''
              }
              style={{ order: node.frontmatter.navOrder }}
            >
              <Link
                to={
                  node.frontmatter.path
                    ? node.frontmatter.path
                    : node.fields.slug
                }
                activeStyle={{ color: primaryColor() }}
              >
                {node.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default Nav
