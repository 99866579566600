import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../Header'
import Nav from '../Nav'
import Footer from '../Footer'
import { MDXProvider } from '@mdx-js/react'

import { StaticQuery, graphql } from 'gatsby'

import ImageGallery from '../ImageGallery'
import TheButton from '../TheButton'
import AlertNewsletter from '../AlertNewsletter'
import AssembliesListing from '../AssembliesListing'

import '../../layouts/layout.scss'

const shortcodes = { ImageGallery, TheButton, AssembliesListing }

export default function TemplateWrapper({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          siteinfo: site {
            siteMetadata {
              title
            }
          }
          nav: allMdx(
            filter: {
              frontmatter: { showInNav: { eq: true } }
              fileAbsolutePath: { regex: "/about/|/support/|/assemblies/" }
            }
            sort: { fields: [frontmatter___navOrder], order: ASC }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                id
                frontmatter {
                  title
                  path
                  navOrder
                  hideFromSubnav
                }
                excerpt
              }
            }
          }
        }
      `}
      render={(data) => (
        <MDXProvider components={shortcodes}>
          <div>
            <Helmet
              title="NAVEL"
              meta={[
                { name: 'description', content: 'Navel Los Angeles' },
                {
                  name: 'keywords',
                  content: 'Navel Los Angeles art space exhibition space',
                },
              ]}
            />
            <Header>{data.siteinfo.siteMetadata.title}</Header>
            <Nav items={data.nav} position="fixed" />
            <Footer position="fixed" />
            <div className="main">{children}</div>
            <AlertNewsletter />
          </div>
        </MDXProvider>
      )}
    />
  )
}
