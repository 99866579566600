import React from 'react'
import { connect } from 'react-redux'

import { toggleNewsletter } from '../../redux/actions'

class Footer extends React.Component {
  render() {
    return (
      <div className={'footer + footer-' + this.props.position}>
        <div className="footer-cols">
          <div className="footer-col">
            <a
              href="https://www.google.com/maps/place/NAVEL/@34.0369421,-118.2688394,17z/data=!4m5!3m4!1s0x80c2c7c69d4c174b:0x430b4b55635b535c!8m2!3d34.0363181!4d-118.2680307"
              target="_blank"
              rel="noreferrer"
            >
              1611 South Hope Street
              <br />
              Los Angeles, CA, USA
            </a>
          </div>
          <div className="footer-col">
            <button
              className={'button-' + this.props.position}
              onClick={() => this.props.toggleNewsletter()}
            >
              Newsletter
            </button>
            <br />
          </div>
          <div className="footer-col">
            <a
              href="https://www.facebook.com/NavelLosAngeles"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.are.na/navel/"
              target="_blank"
              rel="noreferrer"
            >
              Are.na
            </a>
            <br />
            <a
              href="https://www.instagram.com/navel.la/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="https://vimeo.com/navellosangeles"
              target="_blank"
              rel="noreferrer"
            >
              Vimeo
            </a>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  newsletter: state.toggle,
})

const mapDispatchToProps = {
  toggleNewsletter,
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
