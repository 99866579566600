import React from 'react'
import { Link } from 'gatsby'
import formatDate from '../../utils/formatDate'
import NavelImage from '../NavelImage'

class ListingItem extends React.Component {
  render() {
    let node = this.props.node

    return (
      <div
        className={
          'article-box' +
          (node.frontmatter.primaryImage ? '' : ' no-image') +
          ' article-box-' +
          (Math.floor(Math.random() * (4 - 1 + 1)) + 1)
        }
      >
        {node.frontmatter.primaryImage && (
          <Link
            to={node.fields.slug}
            style={{ textDecoration: 'none', color: 'inherit' }}
            className="article-image"
          >
            <NavelImage image={node.frontmatter.primaryImage} />
          </Link>
        )}
        <div className="article-box-meta">
          <Link
            to={node.fields.slug}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <h3 className="article-title">{node.frontmatter.title}</h3>
            <div className="article-subtitle">{node.frontmatter.subtitle}</div>
          </Link>

          {!node.frontmatter.customDate && (
            <p className="article-date">
              {formatDate(
                node.frontmatter.date,
                !this.props.past && node.frontmatter.time
              )}
            </p>
          )}
          {node.frontmatter.customDate && (
            <p className="article-date">
              {node.frontmatter.customDate +
                (!this.props.past && node.frontmatter.time
                  ? ',' + node.frontmatter.time
                  : '')}
            </p>
          )}

          <p className="article-excerpt">
            {node.frontmatter.teaser ? node.frontmatter.teaser : node.excerpt}
          </p>
        </div>
      </div>
    )
  }
}

export default ListingItem
