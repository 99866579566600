import React, { Component } from 'react'
import NavelImage from '../NavelImage'
import Swipe from 'react-easy-swipe'

import { isMobile } from 'react-device-detect'
const mod = (n, m) => ((n % m) + m) % m

const CYCLE_TIME = 5

export default class ImageGallery extends Component {
  state = {
    i: 0,
    maxheight: null,
    countdown: CYCLE_TIME,
    timer: null,
    active: false,
  }

  handleGalleryClick() {
    if (isMobile) {
      if (this.state.active)
        this.setState((state) => {
          return { i: (state.i += 1) }
        })
      else {
        document.body.style.overflow = 'hidden'
        this.setState({ active: true })
        document.querySelector('.gallery-images').scrollIntoView()
      }
    } else {
      this.setState((state) => {
        return { i: (state.i += 1) }
      })
    }
  }
  handleCloseClick() {
    document.body.style.overflow = 'initial'
    this.setState({ active: false })
  }
  cycle() {
    this.setState({ countdown: CYCLE_TIME })
    this.setState({
      timer: setInterval(() => {
        if (this.state.countdown === 0) {
          this.setState((state) => {
            return { i: (state.i += 1) }
          })
          clearInterval(this.state.timer)
          this.cycle()
        } else this.setState({ countdown: this.state.countdown - 1 })
      }, 1000),
    })
  }

  stopCycle() {
    clearInterval(this.state.timer)
  }
  progress(i) {
    this.setState({ i: i })
    clearInterval(this.state.timer)
    this.cycle()
  }
  getImageDimensions = (file) =>
    new Promise(function (resolved, rejected) {
      let i = new Image()
      i.onload = () => resolved({ w: 780, h: (i.height * 780) / i.width })
      i.src = file
    })
  componentDidMount() {
    let height = []
    for (let i = 0; i < this.props.images.length; i++) {
      height.push(
        this.getImageDimensions(
          this.props.images[i].childImageSharp.fixed.base64
        )
      )
    }
    this.setState({ maxheight: height.sort((a, b) => b.h - a.h)[0].h })
    this.cycle()
  }
  componentWillUnmount() {
    this.stopCycle()
  }
  render() {
    return (
      <div className="gallery">
        <span
          role="presentation"
          style={
            this.state.active
              ? {
                  position: `absolute`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `flex-end`,
                  top: window.scrollY,
                  height: `calc(100vh - 25px)`,
                  zIndex: `9999`,
                }
              : { display: `none` }
          }
          onClick={() => this.handleCloseClick()}
        >
          X
        </span>
        <div
          role="presentation"
          className={`gallery-images ${!this.state.active && `closed`}`}
          onClick={() => this.handleGalleryClick()}
          style={
            this.state.active
              ? {
                  position: `absolute`,
                  width: `90vw`,
                  height: `90vh`,
                  padding: `5vh 5vw`,
                  left: 0,
                  top: window.scrollY,
                  background: `white`,
                }
              : null
          }
        >
          {this.props.images.map((el, i, arr) => {
            return (
              <div
                key={i}
                className="gallery-image"
                style={
                  i === mod(this.state.i, arr.length)
                    ? {
                        opacity: 1,
                        position: `initial`,
                      }
                    : {
                        opacity: 0,
                        position: `absolute`,
                      }
                }
              >
                <Swipe
                  onSwipeRight={() =>
                    this.setState((state) => {
                      return { i: (state.i += 1), countdown: CYCLE_TIME }
                    })
                  }
                  onSwipeLeft={() =>
                    this.setState((state) => {
                      return { i: (state.i += 1), countdown: CYCLE_TIME }
                    })
                  }
                >
                  <NavelImage imageStyle={{ height: '480px' }} image={el} />
                </Swipe>
              </div>
            )
          })}
        </div>
        <div className="gallery-controls">
          <span
            role="presentation"
            onClick={() =>
              this.setState((state) => {
                return { i: (state.i += 1), countdown: CYCLE_TIME }
              })
            }
          >
            ←
          </span>

          <span
            role="presentation"
            onClick={() =>
              this.setState((state) => {
                return { i: (state.i += 1), countdown: CYCLE_TIME }
              })
            }
          >
            →
          </span>
        </div>
      </div>
    )
  }
}
